import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html{
    scroll-behavior: ${props => (props.smoothScroll ? 'smooth' : 'unset')};
    overflow: auto;
  }

  * {
    box-sizing: border-box;
  }

  body {
    position: relative;
    padding: 0;
    margin: 0;
    background-color: rgba(14,28,93,1);
    ${props => props.background && `background-color: ${props.background}`}
    ${props => !props.background && 'background-image: -moz-linear-gradient(180deg, rgba(14,28,93,1) 0%, rgba(32,59,188,1) 30%, rgba(14,28,93,1) 51%, rgba(29,54,172,1) 74%, rgba(14,28,93,1) 100%);'}
    ${props => !props.background && 'background-image: -webkit-linear-gradient(180deg, rgba(14,28,93,1) 0%, rgba(32,59,188,1) 30%, rgba(14,28,93,1) 51%, rgba(29,54,172,1) 74%, rgba(14,28,93,1) 100%);'}
    ${props => !props.background && 'background-image: -o-linear-gradient(180deg, rgba(14,28,93,1) 0%, rgba(32,59,188,1) 30%, rgba(14,28,93,1) 51%, rgba(29,54,172,1) 74%, rgba(14,28,93,1) 100%);'}
    ${props => !props.background && 'background-image: -ms-linear-gradient(180deg, rgba(14,28,93,1) 0%, rgba(32,59,188,1) 30%, rgba(14,28,93,1) 51%, rgba(29,54,172,1) 74%, rgba(14,28,93,1) 100%);'}
    ${props => !props.background && 'background-image: linear-gradient(180deg, rgba(14,28,93,1) 0%, rgba(32,59,188,1) 30%, rgba(14,28,93,1) 51%, rgba(29,54,172,1) 74%, rgba(14,28,93,1) 100%);'}
  }

  h1, h2, h3, h4, h5, p, ul, a, button, input, li {
    font-family: 'Poppins', sans-serif;
  }

  h1, h2 {
    margin: 0;
    color: #fff;

    @media(max-width: 1184px) {
      font-size: 18px;
      line-height: 40px;
    }
  }
  
  h1 {
    font-size: 32px;
    line-height: 1.2;
  }

  h2 {
    font-size: 28px;
    line-height: 1.2;

    @media(max-width: 1184px) {
      font-size: 18px;
    }
  }

  h3 {
    margin: 0;
    font-size: 31px;
    color: #fff;

    @media(max-width: 1184px) {
      font-size: 18px;
    }
  }

  h4 {
    margin: 0;
    font-size: 23px;
    color: #fff;

    @media(max-width: 1184px) {
      font-size: 18px;
    }
  }

  h5 {
    margin: 0;
    font-size: 18px;
    color: #fff;

    @media(max-width: 1184px) {
      font-size: 18px;
    }
  }

  p, li {
    margin: 0;
    font-size: 16px;
    line-height: 35px;
    color: #c8d2ff;

    @media(max-width: 1184px) {
      font-size: 12px;
      line-height: 25px;
    }
  }
  
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  li {
    font-size: 14px;
  }

  a {
    text-decoration: none;
    color: #fff;
  }

  button {
    border: none;
    cursor: pointer;
  }

  span.anchor {
    display: block;
    height: 100px;
    margin-top: -100px;
    visibility: hidden;
  }
`;

export default GlobalStyles;